import * as React from "react";
import "./Message.scss";

import { StaticImage } from "gatsby-plugin-image";
import { Link } from "gatsby";

const Message = () => {
  return (
    <section className="message-content">
      <div className="status-modal-container">
        <StaticImage
          src={"../../images/icons/delivery.svg"}
          className="status-modal-illustration"
          alt="ícono de éxito"
          loading="lazy"
        />
        <p className="thanks">¡Muchas gracias!</p>
        <p className="text">
          Un representante se estará comunicando con usted a la brevedad.
        </p>
        <div className="return-button">
        <Link to="/" offset={50}>
          <button type="button">Volver</button>
        </Link>
      </div>
      </div>
     
    </section>
  );
};

export default Message;
